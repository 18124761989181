import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulLocation')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <h1>{post.name}</h1>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query LocationBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLocation(slug: { eq: $slug }) {
      name
      slug
    }
  }
`
